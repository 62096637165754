module.exports = [{
      plugin: require('/home/circleci/repo/node_modules/gatsby-plugin-jss/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"palette":{"basic":{"white":"#ffffff","black":"#000000"},"vortexa":{"primary":"#0090B9","active":"#3F83A4"},"bigBlue":{"hover":"#179ABF","primary":"#151823","canvas":"#22252E","canvasHighlight":"#2E323D"},"fog":{"primary":"#C5C3C6","outline":"#46494C","lightContainer":"#EFEEEF"},"medusa":{"primary":"#C01A4D"},"jellyfish":{"primary":"#FED86A"}},"typography":{"sizes":{"h0":36,"h1":24,"h2":18,"h3":16,"h4":14,"body":14,"tooltip":10},"families":{"default":"Heebo","primary":"Pirulen"}},"basePadding":8,"borderRadius":2,"cardContentPadding":48,"content":{"width":"100%","maxWidth":"1140px","margin":"0px auto"},"narrowContent":{"maxWidth":"800px"}}},
    },{
      plugin: require('/home/circleci/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-142701836-1","head":true},
    },{
      plugin: require('/home/circleci/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
