// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-article-tsx": () => import("/home/circleci/repo/src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-pages-404-tsx": () => import("/home/circleci/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-company-tsx": () => import("/home/circleci/repo/src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("/home/circleci/repo/src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-index-tsx": () => import("/home/circleci/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insight-tsx": () => import("/home/circleci/repo/src/pages/insight.tsx" /* webpackChunkName: "component---src-pages-insight-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("/home/circleci/repo/src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-request-demo-tsx": () => import("/home/circleci/repo/src/pages/request-demo.tsx" /* webpackChunkName: "component---src-pages-request-demo-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/circleci/repo/.cache/data.json")

